<!-- eslint-disable vue/no-v-html -->
<!-- @format -->

<template>
    <div class="gst-checkout-confirmation d-flex flex-column">
        <div class="px-4">
            <div class="gst-checkout-confirmation__container">
                <p class="text-center mt-6 mb-4 gst-checkout-confirmation__title">
                    {{ $t('title') }}
                </p>
                <h6
                    class="text-center tertiary--text gst-checkout-confirmation__subtitle mb-4 px-5"
                    v-html="subtitle"></h6>
                <BonusPointsCartCheckoutConfirmation
                    :points="bonusPointsEarn"
                    :currency="loyaltyCurrency || currency"
                    :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                    class="mt-md-6 mb-4" />
                <ticket-preview-image
                    class="mb-4"
                    :image-props="{
                        src: event.imageUrl,
                        width: '100%',
                        height: '90px',
                        gradient: gradient,
                    }"
                    :text="event.name" />
                <ticket-date-icon
                    :start-date-time="event.startDateTime"
                    :end-date-time="event.endDateTime"
                    class="mb-2" />
                <ticket-location-icon
                    :venue="venueLocation"
                    class="mb-4" />
                <ticket-card-variant-two
                    :ticket="ticketLocation"
                    :outlined="true"
                    :event="event"
                    class="u-width-100 u-background-transparent mb-4 gst-checkout-confirmation__ticket-card" />
                <OrderSummary
                    :cart-collection="eventTicket"
                    :order="order"
                    :payment-model="paymentModel"
                    :shipping-model="{ options: [shippingOption] }"
                    is-order-confirmation />
            </div>
        </div>
        <div
            v-if="showDownloadTicketSection"
            class="gst-checkout-confirmation__container pt-6">
            <BaseButton
                :style="{ width: '100%' }"
                :placeholder="$t('buttons.download.title')"
                :href="downloadUrl" />
            <p class="gst-checkout-confirmation__button-additional-info">
                {{ $t('buttons.download.additionalInformation') }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import LogService from '@core/services/LogService';
import { format as dateUtilsFormat } from '@core/utils/dateUtils';
import TheOrderConfirmation from '@core/views/cart/TheOrderConfirmation.vue?CORE';
import iFrameService from '@tenant/services/iFrameService';

export default {
    name: 'TheOrderConfirmation',
    extends: TheOrderConfirmation,
    computed: {
        showDownloadTicketSection() {
            /**
             * Inside Iframe we cannot open in a new tab
             */
            return false;
        },
    },
    methods: {
        ...mapActions({
            loadEventAttractions: 'attractions/searchByEvent',
            loadOneEvent: 'events/one',
            setLoading: 'appState/setLoading',
        }),
        async sendOrderInfoToTenantParentIFrame() {
            const ticketReservation = this.order.items[0];
            const eventId = ticketReservation.event.id;
            let payload = {
                teamsInvolved: [],
                ticketQuantity: ticketReservation.ticket.quantity || null,
                orderAmount: ticketReservation.totalAmount || null,
                feeDiscountAmount: ticketReservation.discounts
                    ? ticketReservation.discounts[0].amount * ticketReservation.ticket.quantity
                    : null,
                dateOfTheEvent: ticketReservation.event.startDateTime
                    ? dateUtilsFormat(ticketReservation.event.startDateTime, 'MM-dd-yyyy', 'en')
                    : null,
                orderId: ticketReservation.orderNumber || null,
                eventId: ticketReservation.legacyEventId || null,
                venueId: null,
                inStateOrNot: ticketReservation.event.address.countryCode === 'US',
            };

            this.setLoading(true);
            Promise.all([
                this.loadEventAttractions({ eventId: eventId }),
                this.loadOneEvent(eventId),
            ])
                .then((values) => {
                    const [eventAttractionsResponse, event] = values;
                    if (eventAttractionsResponse.success) {
                        payload.teamsInvolved = eventAttractionsResponse.data.list
                            .map((item) => item.partnerAttractionId)
                            .slice(0, 2);
                    } else {
                        LogService.error('Error during event attraction');
                    }
                    if (event) {
                        payload.venueId = event.partnerVenueId;
                    } else {
                        LogService.error('Error during event details');
                    }
                    LogService.debug('Sending payload', payload);
                    iFrameService.sendPurchaseSuccessEvent(payload);
                })
                .catch((e) => {
                    LogService.debug(
                        'Error during sending payload to parent iframe',
                        e.message,
                        payload
                    );
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },
    },
    async mounted() {
        if (!this.order) {
            this.$router.back();
        } else {
            await this.sendOrderInfoToTenantParentIFrame();
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@scssVariables';
@import '@scssMixins';

.gst-checkout-confirmation {
    @include viewport-height(100, !important);
}

.gst-checkout-confirmation__icon-back {
    position: absolute;
    left: 20px;
}

.gst-checkout-confirmation__title {
    color: theme-color('quaternary');
    font-size: font-size('xxxxxl');
    font-weight: font-weight('bold');
}

.gst-checkout-confirmation__subtitle {
    line-height: line-height('xxxl');
    font-size: font-size('s');
    font-weight: font-weight('regular');
}

.gst-checkout-confirmation__ticket-card {
    padding: theme-spacing(3);
}

.gst-checkout-confirmation__container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;

    .gst-checkout-confirmation__button-additional-info {
        margin: theme-spacing(6, 8);
        color: theme-color('tertiary');
        text-align: center;
    }
}
</style>
